.inputPass:focus {
    border-color: #0078d3;
    outline: none;
    box-shadow: 0 0 7px rgba(89, 184, 255, 0.5);
}

.accountMain {
    background-color: #f7f7f7;
    width: 70%;
    margin-left: 350px;
    margin-bottom: 10%;
}

.header {
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img {
    width: 130px;
    height: 100px;
}

.divCadastroAccounts {
    display: flex;
    flex-direction: row;
    padding: 2%;
}

.label1 {
    color: rgb(26, 26, 26);
    margin-bottom: 5px;
}

.divCad1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2%;
    justify-content: center;
    align-items: center;
}

.inputWrapper1 {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 50%;
}

.labelPass1 {
    color: rgb(37, 37, 37);
    margin-bottom: 5px;
}

.inputPass1 {
    font-size: 14px;
    border: 2px solid rgba(116, 116, 116, 0.26);
    border-radius: 5px;
    height: 24px;
    padding: 1vh;
    width: 80%;
    box-shadow: 0px 0px 3px rgba(84, 84, 84, 0.305);
}

.button1 {
    width: 50%;
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
    padding: 10px 40px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #9ad413;
    border-radius: 8px;
    cursor: pointer;
}

.inputAccount {
    font-size: 16px;
    text-align: left;
    border-width: 0.5px;
    border-style: solid;
    border-radius: 5px 5px 5px 5px;
    border-color: rgba(164, 164, 164, 0.26);
    height: 24px;
    padding: 1vh;
    width: 80%;
}

.inputAccount:hover {
    border-color: #5656563e;
    outline: none;

}

.box {
    margin: 3%;
    background-color: white;
    padding: 5%;
}

.botao {
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
    font-size: 14px;
    font-weight: bold;
    color: #9ad413;
    cursor: pointer;
}



.whiteBackground {
    border-top: 5px #1a2f67 solid;

    background-color: #ffffff;
    padding: 6vh;
    border-radius: 8px 8px 8px 8px;
    box-shadow: 0 0 7px #a8a8a8;
}

.whiteBackground h2 {
    color: #00336a;
}

@media screen and (min--moz-device-pixel-ratio: 0.9),
screen and (-o-min-device-pixel-ratio: 0.9/1),
screen and (-webkit-min-device-pixel-ratio: 0.9),
screen and (max-resolution: 109dpi) {
    .div1 {
        font-size: 20px;
    }

    .accountMain {
        margin-left: 450px;
    }

    .inputAccount {
        font-size: 18px;
        height: 32px;
    }

    .inputPass1 {
        height: 32px;
        font-size: 18px;
    }

    .inputPass {
        height: 32px;
    }

    .button1 {
        font-size: 18px;
    }
}