.docBody {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.error-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: red;
    color: white;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}


.DocGenHeader {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1a2f67;
    border-radius: 10px;
    transition: background-color 0.3s;
    width: 22%;
}

/* Estilos para o container dos 4 quadradinhos */
.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* Estilos comuns para cada quadradinho */
.inputsQuadradinho {
    width: 40px;
    height: 40px;
    font-size: 20px;
    border: 2px solid #333;
    border-radius: 4px;
    margin-right: 10px;
}

/* Estilo para realçar o quadradinho ativo quando em foco */
.inputsQuadradinho:focus {
    outline: none;
    border: 2px solid #007bff;
    /* Altere a cor de destaque desejada */
}


.DocGenDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: space-between;
    min-width: 10%;
    margin-right: 10vh;
}

.divIconAndText {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;

    padding: 2vh;
    border-radius: 8px;
    background-color: #1a2f67;
}

.DocGenButton {
    color: #fff;
    border: none;
    font-size: 20px;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s;
}

.DocGenButton1 {
    position: relative;
    background-color: rgb(252, 63, 63);
    width: 7vh;
    margin-right: 5vh;
    justify-self: flex-start;
    align-self: flex-start;
}

.justifyRight {
    top: 10vh;
}

.justifyLeft {
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    position: absolute;
    top: 10vh;
    left: 27%;
}

.justifyRight1 {
    width: 130vh;
    border-top: 5px darkgrey solid;
    background-color: #1a2f67;
    border-radius: 10px 10px 10px 10px;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 5vh;
    margin-top: 2vh;
}

.mainDoc1 {
    display: flex;
    flex-direction: column;
    border-top: 5px #1a2f67 solid;
    border-radius: 10px 10px 10px 10px;
    margin: 3%;
    padding: 3%;
    background-color: #fefeff;
    min-width: 100vh;
    align-items: start;
    margin-left: 25%;
}

.mainDoc2 {
    display: flex;
    flex-direction: column;
    border-top: 5px #1a2f67 solid;
    margin: 3%;
    background-color: #fefeff;
    min-width: 100vh;
    align-items: center;
}

.mainDoc1 button {
    width: 50vh;
}

.formDoc {
    border-left: 5px #1a2f67 solid;
    margin: 3%;
    padding: 3%;
    background-color: #fefeff;
    border-radius: 10px 10px 10px 10px;
    margin-left: 25%;
    min-width: 100vh;
}

.rowDivs1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: start;
    width: 100%;
}

.divInputsDocGen {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

}

.divInputsDoc5 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 46%;
}

.labelInputsDoc {
    font-weight: 600;
    text-align: left;
}

.inputsDoc {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding-left: 2vh;
    padding-right: 2vh;
    height: 35px;
    border: #cfcfcf 2px solid;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 73vh;
    margin-bottom: 2vh;
}

.h1Doc {
    color: #1a2f67;
    font-size: 20px;
    font-weight: 600;
    text-align: start;
    margin-bottom: 3vh;
    margin-top: 3vh;
}

.selectDoc {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding-left: 3%;
    padding-right: 3%;
    height: 35px;
    border: #cfcfcf 2px solid;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 2vh;
    margin-bottom: 2vh;
    width: 78vh;
}

.optionInputsDoc {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding-left: 3%;
    padding-right: 3%;
    height: 35px;
    border: #cfcfcf 2px solid;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 2vh;
}

.mainDoc {
    width: 80%;
    margin-left: 23%;
    margin-bottom: 20%;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.subDiv {
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;
}

.newButton {
    cursor: pointer;
    color: #000000;
    border-top: #9ad413 2px solid;
    padding: 10px;
    justify-content: flex-start;
    width: 100%;
}

/* Estilos para a classe pdf-container */
.pdf-container {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
}

.pdf-container.active {
    opacity: 1;
    visibility: visible;
}

/* Estilos para o botão de fechar */
.close-button1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 1%;
    padding: 1%;
    height: 1%;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.close-button1:hover {
    background-color: #c0392b;
    color: #fff;
}


.content {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.titlee {
    color: #1a2f67;
    padding: 20px;
    margin-bottom: 100px;
    text-align: start;
    font-size: 1.2rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.listButton {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: transparent;
    border-bottom: #000000;
    color: #fff;
    text-align: center;
    font-size: 14px;
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
}

.buttonsContainer23 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.docButton1 {
    background-color: #1a2f67;
    height: 6vh;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 94%;
    text-align: start;
    padding: 3%;
}


.docButton1:hover {
    background-color: #7682a4;
}

.DocGenIcon {
    display: flex;
    font-size: 40px;
    color: #1a2f67;
    text-align: center;
}


@media screen and (min--moz-device-pixel-ratio: 0.9),
screen and (-o-min-device-pixel-ratio: 0.9/1),
screen and (-webkit-min-device-pixel-ratio: 0.9),
screen and (max-resolution: 119dpi) {
    .docBody {
        font-size: 20px;
    }

    .inputsDocPorcento {
        width: 100%;
        font-size: 18px;
        height: 45px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        outline: none;
    }

    .inputsDocPorcento:focus {
        border-color: #007bff;
        /* Cor da borda ao receber foco */
    }

    .optionInputsDoc {
        font-size: 18px;
        height: 45px;
    }
}

.loader {
    margin-top: 10%;
    border: 1px solid #000000;
    border-radius: 8px;
    background-color: #fff;
    color: #000000;
}