@media screen and (max-width: 1050px) {
    .bodyLogin {
        font-family: Arial, sans-serif;

    }

    .background {
        border-radius: 8px;
    }

    .containerWeb {
        padding: 0rem 2rem 0rem 2rem;
        justify-content: center;
        align-items: center;
        width: 95%;
        left: 25%;
        justify-content: center;
        align-content: center;
        border-radius: 8px;

    }

    .bodyLogin {
        font-family: Arial, sans-serif;
        background-size: cover;
    }

    .input-password {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        border: 1px;
        background-color: #f7f7f7;
        color: #63636394;

    }

    .title {
        text-align: left;
        font-size: 1.3rem;
        font-weight: 600;
        color: #1b2c66;
        width: 0;
    }

    .title11 {
        text-align: left;
        font-size: 1.3rem;
        font-weight: 600;
        color: #1b2c66;
    }

    .title1 {
        width: 0;
        text-align: left;
        font-weight: 600;
        font-size: 1.3rem;
        color: #9ad413;
    }

    .label {
        text-align: left;
        font-size: 1rem;
        font-weight: 600;
        color: #000000;
    }

    .input-container {
        width: 100%;
        font-weight: 700;
        box-sizing: border-box;
    }

    .input-container-input {
        height: 2.5rem;
        width: 100%;
        padding-left: 2rem;
        border: 1px solid #63636394;
        border-radius: 6px;
        box-shadow: #33333346 0px 0px 5px;
        font-size: 1rem;

    }

    .icon {
        position: absolute;
        left: 0.5rem;
        font-size: 1.3rem;
    }

    .input-container button svg {
        fill: #59b8ff;
        ;
    }

    .input-container button svg {
        fill: #59b8ff;
        ;
    }

    .button-container {
        display: flex;
        flex-wrap: wrap;
    }

    .button {
        width: 90%;
        background-color: #9ad413;
        color: #ffffff;
        border: none;
        height: 6vh;
        border-radius: 4px;
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .button:hover {
        background-color: #277ee2;
    }

    .forgot-password {
        text-align: end;
        border: none;
        background: none;
        outline: none;
        box-shadow: none;
        font-size: 1.3rem;
        font-weight: bold;
        color: #9ad413;
        border-radius: 8px;
        cursor: pointer;
    }

    .black {
        color: black;
        font-size: 1.3rem;
        font-weight: normal;
        padding-bottom: 0%;
    }

    .cadastro {
        border: 0px;
        background-color: #f7f7f7;
        font-size: 1.3rem;
        color: #9ad413;
        cursor: pointer;
    }

    .input-password {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        position: relative;
        border: 1px;
        background-color: #f7f7f7;
        color: #63636394;

    }

    .buttonEye {
        position: absolute;
        right: 1rem;
        cursor: pointer;
    }


    .criar {
        justify-content: center;
        align-content: center;
    }
}

@media screen and (min-width: 1050px) {
    .bodyLogin {
        padding-top: 1rem;
        font-family: Arial, sans-serif;
        background-color: #00336a;
        background-image: url('../images/background-web.jpg');
        background-size: cover;
    }

    .background {
        display: flex;
        height: 100vh;
        align-items: center;
        margin-top: 10vh;
    }

    .container1 {
        align-self: center;
        width: 30%;
    }

    .containerWeb {
        padding: 2rem;
        width: 37%;
        position: absolute;
        right: 4rem;
    }

    .title {
        text-align: left;
        font-size: 2rem;
        font-weight: 600;
        color: #1b2c66;
    }

    .title2 {
        font-size: 1.3rem;
        font-weight: 400;
        color: #1b2c66;
        white-space: nowrap;
        /* Impede quebras de linha */
        display: inline;
        /* Exibe em uma única linha */
    }

    .title1 {
        font-size: 1.3rem;
        font-weight: 600;
        color: #9ad413;
        white-space: nowrap;
        /* Impede quebras de linha */
        display: inline;
        /* Exibe em uma única linha */
    }





    .title11 {
        font-size: 1.3rem;
        font-weight: 600;
        color: #1b2c66;
        margin: 0;
    }

    .divConta {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: center;
        width: 100%;
        margin-bottom: 10px;
    }

    .label {
        text-align: left;
        font-size: 1.2rem;
        font-weight: 400;
        color: #000000;
    }

    .input-container {
        width: 100%;
        font-weight: 700;
        box-sizing: border-box;
    }

    .input-container-input {
        height: 6.5vh;
        width: 100%;
        padding-left: 2.5rem;
        border: 1px solid;
        border-color: #63636394;
        border-radius: 6px;
        box-shadow: #33333346 0px 0px 5px;
        font-size: 1.2rem;
    }

    .input-container-input:focus {
        outline: none;
        border-color: #9ad413;
    }

    .icon {
        position: absolute;
        left: 0.5rem;
        font-size: 1.4rem;
    }

    .input-container button svg {
        fill: #59b8ff;
        ;
    }

    .button-container {
        display: flex;
        flex-wrap: wrap;
    }

    .buttonEntrar {
        width: 100%;
        box-shadow: #33333346 0px 0px 5px;
        background-color: #93c11e;
        color: #ffffff;
        border: none;
        height: 7vh;
        border-radius: 4px;
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .buttonEntrar:hover {
        background-color: #074893;
    }

    .forgot-password {
        text-align: end;
        border: none;
        background: none;
        outline: none;
        box-shadow: none;
        font-size: 1.3rem;
        margin: 0.5rem 0 0.5rem 0;
        font-weight: bold;
        color: #9ad413;
        border-radius: 8px;
        cursor: pointer;
    }

    .black {
        color: black;
        font-size: 1rem;
        font-weight: normal;
        padding-bottom: 0%;
    }

    .cadastro {
        border: 0px;
        background-color: #f7f7f7;
        font-size: 1.3rem;
        color: #9ad413;
        cursor: pointer;
    }

    .input-password {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        position: relative;
        border: 1px;
        background-color: #f7f7f7;
        color: #63636394;
    }

    .buttonEye {
        position: absolute;
        right: 1rem;
        cursor: pointer;
        height: 1rem;
    }

    .criar {
        justify-content: center;
        align-content: center;
    }

    .toggle-button {
        width: 100%;
        background-color: #f7f7f7;
        border: 1px solid #00336a;
        color: #00336a;
        font-size: 1rem;
    }
}

@media screen and (min--moz-device-pixel-ratio: 0.9),
screen and (-o-min-device-pixel-ratio: 0.9/1),
screen and (-webkit-min-device-pixel-ratio: 0.9),
screen and (min-resolution: 130dpi) {
    .containerWeb {
        width: 37%;
        position: absolute;
        right: 25px;
    }
}

@media screen and (min--moz-device-pixel-ratio: 0.9),
screen and (-o-min-device-pixel-ratio: 0.9/1),
screen and (-webkit-min-device-pixel-ratio: 0.9),
screen and (min-resolution: 149dpi) {
    .containerWeb {
        width: 40%;
    }

    .title {
        font-size: 1.3rem;
    }

    .title1 {
        font-size: 1.3rem;
    }

    .label {
        font-size: 1rem;
    }

    .input-container-input {
        font-size: 1rem;
    }

    .forgot-password {
        font-size: 1.3rem;
    }

    .button {
        font-size: 1.3rem;
    }

    .cadastro {
        font-size: 1.3rem;
    }

    .toggle-button {
        width: 100%;
        background-color: #f7f7f7;
        font-size: 1rem;
    }
}