.formInicio {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    margin-left: 25%;
    width: 75%;
    justify-content: center;
    border-top: #1a2f67 5px solid;
    border-radius: 10px 10px 10px 10px;
    box-shadow: #00000061 0px 1px 2px;
}

.inicioBody {
    width: 95%;
    margin: 10px;
}

.inicioMain {
    background-color: #ffffff;
    width: 100%;
}

.inputsInicio {
    height: 4vh;
    border: 1px solid #1a2f67;
    border-radius: 5px;
}

.div2 {
    display: flex;
    width: 100%;
    justify-content: end;
    color: #1a2f67;
}

.buttonAddCola {
    display: flex;
    width: 100%;
    justify-content: end;
    height: 3.3rem;
    margin-bottom: 1rem;

}

.button22 {
    margin-top: 1rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 1.3rem;
    margin-bottom: 1rem;
}

.buttonAddCol {
    color: white;
    cursor: pointer;
    background-color: #1a2f67;
    border-radius: 4px;
    padding: 0.9rem;
    font-size: 1rem;
    transition: background-color 0.3s;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.tableEmployees {
    width: 100%;
}

.headEmployees {
    width: 100%;
}

.headEmployees tr th {
    background-color: #ffffff;
    border: 0px;
    color: #1a2f67;
    text-align: center;
}

.bodyEmployees tr td {
    border: 0px;
    text-align: center;
    padding-bottom: 1%;

}

@media (min-width: 1800px) {
    .tableEmployees {
        width: 100%;
        font-size: 1.5rem;
    }

    .tableEmployees tr th {}
}