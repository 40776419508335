.headerHome1 {
    margin-right: 1rem;
    background-color: #1a2f67;
    border: 2px;
    border-radius: 8px;
    align-self: flex-start;
    height: 97vh;
    width: 100%;
}

.headerHome2 {
    margin-right: 1rem;
    background-color: #020608;
    border: 2px;
    border-radius: 8px;
    align-self: flex-start;
    height: 97vh;
    width: 100%;
}

.fixed {
    position: fixed;
    top: 1%;
    width: 42vh;
    height: 100%;
}

.headerImg1 {
    width: 8rem;
    margin-right: 1rem;
    margin-top: 1.4rem;
}

.divHeader1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

}

.headerBar {
    display: flex;
    align-items: center;
    background-color: #92c01e;
    border-radius: 0px 50px 50px 0px;
    width: 20rem;
    margin-right: 2vh;
    height: 1%;
    justify-content: flex-start;
    padding-inline-start: 4vh;
}

.headerBar2 {
    display: flex;
    align-items: center;
    border-radius: 0px 50px 50px 0px;
    width: 20rem;
    height: 1%;
    justify-content: flex-start;
    padding-inline-start: 4vh;
    cursor: pointer;

}

.logout {
    display: flex;
    align-items: center;
    border-radius: 0px 50px 50px 0px;
    width: 20rem;
    justify-content: flex-start;
    padding-inline-start: 3.1vh;
    cursor: pointer;
    color: white;
    margin-top: 1rem;

}

.logout:hover {
    transition: 800ms;
    background-color: #81a1c081;
    width: 20rem;
    margin-right: 2vh;
}

.headerBar2:hover {
    transition: 800ms;
    background-color: #81a1c081;
    width: 20rem;
    margin-right: 2vh;
}

.headerH1,
.headerH3 {
    padding-inline: 1rem;
    color: white;
    text-align: center;
}

.headerH1 {
    font-size: 2rem;

}

.headerH3 {
    font-size: 2rem;

}

.headerHome {
    height: 90vh;
    padding-bottom: 2rem;

}

.headerImg {
    width: 30rem;

}

.botaoLogout1 {
    font-size: 1rem;
    padding-inline: 1rem;
    color: white;

}

.headerContainer1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    max-width: 95%;

}

/* Estilos padrão para telas maiores */
.headerH11 {
    font-size: 0.9rem;
    padding-inline: 0.5rem;
    color: white;

}

.headerH3 {
    font-size: 0.5rem;

}

.headerImg {
    width: 30rem;

}

/* Media query para telas menores, como MacBook */
@media (max-width: 1300px) {
    .headerH11 {
        font-size: 0.7rem;

        /* Tamanho menor para telas menores */
    }

    .headerH3 {
        font-size: 0.7rem;
        /* Tamanho menor para telas menores */
    }

    .headerImg1 {
        width: 7rem;
    }
}

@media (min-width: 1600px) {
    .headerH11 {
        font-size: 1rem;
        /* Tamanho maior para telas maiores */
    }

    .headerH3 {
        font-size: 1rem;
        /* Tamanho maior para telas maiores */
    }

    .headerImg1 {
        width: 10rem;
        /* Largura maior para telas maiores */
    }

    .botaoLogout {
        font-size: 1.2rem;
        padding-top: 1rem;
        padding-bottom: 0rem;
    }
}

@media (min-width: 1800px) {
    .headerH11 {
        font-size: 1.2rem;
        /* Tamanho maior para telas maiores */
    }

    .headerH3 {
        font-size: 1.5rem;
        /* Tamanho maior para telas maiores */
    }

    .headerImg1 {
        width: 10rem;
        /* Largura maior para telas maiores */
    }

    .botaoLogout {
        font-size: 1.2rem;
        padding-top: 1rem;
        padding-bottom: 0rem;
    }
}