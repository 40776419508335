/* styles.css */

.notas-container {
    margin-top: 20px;
    width: 100%;
    background-color: '#1b2f67';
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 10px;
}

.nota-card {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}

.nota-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
}

.nota-property {
    font-weight: bold;
    margin-right: 4px;
}

.nota-value {
    margin-bottom: 8px;
}

.nenhuma-nota {
    font-size: 14px;
    font-style: italic;
}